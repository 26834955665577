import { pathnameContainsNonRunnablePages, makeGatewayCallPromise } from './dependecies/logincheck.api.es6';
import { ME_SESSION_STORAGE_KEYS } from './dependecies/mesessionstoragekeys.es6';
import { logIfDebugEnabled, htmlToElement, DEBUG_SESSION_KEY } from './dependecies/utils.es6';
import MD5 from './dependecies/md5HashGenerator.es6';

const LANDING_PAGE_PARAMETER = 'OneWeb_registration'; // OneWeb_registration // ONEDOC_SPA
const THEME_PARAMETER = 'oneweb';
const oneDocSpaId = 'oneDoCSpa';
const MINIMAL_HEIGHT_PAYLOAD_THRESHOLD = 10;
const DOC_ACTIVE_EVENT_NAME = 'DOC_ACTIVE_EVENT_V2';
const RECOMMENDATION_EVENT = 'AskNewDOC'; //TODO uncomment this line for environment
//const RECOMMENDATION_EVENT = 'DoNotAsk'; // TODO comment this line

let loginCheckParameters,
    templateParameters,
    userData,
    sendCustomerDataJsonToSPA = false;

let _isConsentGiven = function () {
    return window.sessionStorage.getItem(ME_SESSION_STORAGE_KEYS.dataUsageScopes);
},

    // this case is needed if user closes the overlay by clicking outside of it, so the overlay is not shown anymore in the same session
    _wasSpaCalled = function () {
        const value = window.sessionStorage.getItem(ME_SESSION_STORAGE_KEYS.openedDeclarationOfConsent);
        if (value) {
            return value;
        }
        return false;
    },

    _setSpaStatusToSessionStorage = function () {
        logIfDebugEnabled('Opened DOC SPA');
        window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.openedDeclarationOfConsent, 'true');
        window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.declarationOfConsent, 'false');
    },

    _buildCustomerDataJsonFromConfigAndSessionStorage = function (forceSpa, loginCheckParameter) {
        const { locale, email, given_name: firstName, family_name: lastName, phone_number: phoneNumber } = userData;

        const { marketConfig, b2xLoginData } = loginCheckParameter;

        var country = b2xLoginData.country;
        var legalentity = marketConfig.legalentity
        var consentvariant = marketConfig.consentvariant

        templateParameters = {
            s: LANDING_PAGE_PARAMETER,
            m: country,
            l: locale,
            le: legalentity,
            pnf: firstName,
            pnl: lastName,
            ea: email,
            tt: phoneNumber ? 'M' : 'L',
            tn: phoneNumber ? phoneNumber : null,
            loc: locale,
            vari: consentvariant,
            theme: THEME_PARAMETER,
            ds: forceSpa ? 0 : 1,
           // as: null,// street
           // ah: null, // houseNo
           // al: null, // city,
           // azip: null, // zipCode,
            country: country // countryCode,
        };

        logIfDebugEnabled('Build JSON from config:', templateParameters);
        return JSON.stringify(templateParameters);

    },

    _getDataEventHostOrigin = function (host) {
        return host.substr(0, host.lastIndexOf('/'));
    },

    _addUserData = function (forceSpa, loginCheckParameter) {
        const docIframe = document.getElementById(oneDocSpaId);
        const userDataString = _buildCustomerDataJsonFromConfigAndSessionStorage(forceSpa, loginCheckParameter);
        docIframe.contentWindow.postMessage(`spaCustomerDataMessage(${userDataString})`, '*');
    },

    _addLightboxTemplate = function (loginCheckParameter, forceSpa) {
        let node = _getHtml();
        document.body.appendChild(node);
        _addDocIframe(loginCheckParameter, forceSpa);
    },

    _fetchDataUsageScopes = function (loginCheckParameter = {}) {
        const { marketConfig, b2xLoginData, bffURL } = loginCheckParameter;

        var country = b2xLoginData.country;
        var legalentity = marketConfig.legalentity
        var token = b2xLoginData.authState.jwe;
        const body = _createRequestBody(country, legalentity);

        return makeGatewayCallPromise(bffURL, token, body);
    },

    _createRequestBody = function (countryCode, legalEntity) {
        var dataScopesSecretHash = "123456" // TODO
        const body = {
            searchContext: {
                legalEntity: legalEntity,
                system: LANDING_PAGE_PARAMETER
            },
            purpose: 'Direct Marketing',
            crossCustomerTypeSearchAllowed: true,
            customerSearchData: {
                customerType: 'Person'
            },
            hashCode: MD5(
                [
                    countryCode,                // market
                    LANDING_PAGE_PARAMETER,     // system
                    legalEntity,                // legalEntity
                    '',                         // companyName1
                    '',                         // companyName2
                    '',                         // externalKeyIssuer
                    '',                         // externalKeyValue
                    dataScopesSecretHash // TODO
                ].join(';')
            )
        };

        return JSON.stringify(body);
    },

    _writeDataUsageScopesToStorage = function (response = {}, skipRecommendation = false) {

        const { customerConsentState, customerFound, recommendation, ciasUserData } = response

        userData = ciasUserData

        if (customerConsentState) {
            const { enableDataUsage } = customerConsentState;
            window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.declarationOfConsent, JSON.stringify(enableDataUsage));
        }

        console.log('recommendation', recommendation);
        console.log('skipRecommendation', skipRecommendation);
        console.log('RECOMMENDATION_EVENT', RECOMMENDATION_EVENT);

        if (recommendation === RECOMMENDATION_EVENT && !skipRecommendation) {
            logIfDebugEnabled('No customer consent state or customer not found => calling DOC SPA');
            document.dispatchEvent(new Event(DOC_ACTIVE_EVENT_NAME));
            logIfDebugEnabled(`Recommendation ${recommendation}`);
        }
        else if (customerConsentState && customerFound) {
            const { dataUsageScopes } = customerConsentState;
            if (dataUsageScopes) {
                logIfDebugEnabled('Write dataUsageScopes to session storage', dataUsageScopes);
                window.sessionStorage.setItem(ME_SESSION_STORAGE_KEYS.dataUsageScopes, JSON.stringify(dataUsageScopes));
            }
        }
    },

    _addDocIframe = function (loginCheckParameter, forceSpa) {
        if (loginCheckParameter.spaURL) {
            let oneDoc = document.createElement('iframe'),
                oneDocContainer = document.getElementById('one-doc-container'),

                spaCompletedCallback = function () {
                    let modalWrapper = document.getElementById('one-doc-modal-wrapper');
                    if (modalWrapper) {
                        modalWrapper.remove();
                    }
                    let skipRecommendation = true;
                    _makeDataUsageScopesRequestAndValidateResponse(loginCheckParameter, skipRecommendation);
                },

                setIframeHeight = function (payload) {
                    // To prevent height jumping wait until the final result arrives.
                    if (payload < MINIMAL_HEIGHT_PAYLOAD_THRESHOLD) {
                        return;
                    }

                    // Just to make sure that the SPA gets its data!
                    if (!sendCustomerDataJsonToSPA) {
                        sendCustomerDataJsonToSPA = true;
                        _addUserData(forceSpa, loginCheckParameter);
                    }

                    oneDoc.style.height = payload + 'px';
                    _setSpaStatusToSessionStorage();
                },

                messageHandler = function (messageData) {
                    if (messageData.origin === _getDataEventHostOrigin(loginCheckParameter.spaURL)) {
                        const start = messageData.data.indexOf('(');
                        const end = messageData.data.lastIndexOf(')');
                        const method = messageData.data.substring(0, start);

                        logIfDebugEnabled(`Called method ${method}`, messageData);

                        switch (method) {
                            case 'spaErrorCallback':
                                spaCompletedCallback();
                                break;
                            case 'spaSkippedCallback':
                                spaCompletedCallback();
                                break;
                            case 'setIframeHeight':
                                const payload = JSON.parse(messageData.data.substring(start + 1, end));
                                setIframeHeight(payload);
                                break;
                            case 'spaCompletedCallback':
                                spaCompletedCallback();
                                break;
                            default:
                                spaCompletedCallback();
                        }
                    }
                };
            window.addEventListener('message', messageHandler);
            oneDoc.setAttribute('id', oneDocSpaId);
            // start as a hidden iframe
            oneDoc.setAttribute('style', 'width:100%;height:500px;border:none;');
            oneDoc.setAttribute('src', loginCheckParameter.spaURL);
            // Make sure the SPA is loaded before sending data to SPA
            oneDoc.addEventListener('load', () => {
                _addUserData(forceSpa, loginCheckParameter);
            });
            oneDocContainer.appendChild(oneDoc);
        }
    },

    _makeDataUsageScopesRequestAndValidateResponse = function (loginCheckParameter, skipRecommendation) {
        _fetchDataUsageScopes(loginCheckParameter)
            .then(dataUsage => {
                logIfDebugEnabled('Request for dataUsageScopes succeeded');
                _writeDataUsageScopesToStorage(dataUsage, skipRecommendation);
            })
            .catch(() => {
                logIfDebugEnabled('Request for dataUsageScopes failed');
            });
    },

    _addReactivationEventListener = function () {
        logIfDebugEnabled('Add reactivation event listener');
        document.addEventListener(DOC_ACTIVE_EVENT_NAME, () => {
            window.sessionStorage.removeItem(ME_SESSION_STORAGE_KEYS.declarationOfConsent);
            window.sessionStorage.removeItem(ME_SESSION_STORAGE_KEYS.openedDeclarationOfConsent);
            _addLightboxTemplate(loginCheckParameters, true);
            logIfDebugEnabled('Reactivation Event triggered');
        });
    },

    _getHtml = function () {
        const element = htmlToElement(`
                <div id="one-doc-modal-wrapper">
                   <wb-modal mode="info">
                        <wb-modal-content>
                            <div id="one-doc-container"></div>
                        </wb-modal-content>
                   </wb-modal>
                </div>
            `);
        return element;
    };

export default function declarationOfConsent(loginData) {

    window.sessionStorage.setItem(DEBUG_SESSION_KEY, true)

    logIfDebugEnabled('Start loading', loginData);

    loginCheckParameters = loginData;

    _addReactivationEventListener();

    const { marketConfig } = loginCheckParameters;

    if (!marketConfig.isEnabled) {
        logIfDebugEnabled('Not executing because logincheck is disabled');
        return;
    }

    if (marketConfig && pathnameContainsNonRunnablePages(marketConfig.nonRunnablePages)) {
        logIfDebugEnabled('Not executing because logincheck is disabled on this page');
        return;
    }
    else
    {
        logIfDebugEnabled('Executing because logincheck is enabled on this page');
    }

    if (_isConsentGiven() || _wasSpaCalled()) {
        logIfDebugEnabled('Not executing because session storage indicate that it was already executed');
        return;
    }

    _makeDataUsageScopesRequestAndValidateResponse(loginCheckParameters);
}
